import io from "socket.io-client";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { socketConnectiontype } from "../utils/constant";
import toast from "react-hot-toast";
import { store } from "../app/store";
import { disconnnectSocketDesktop } from "../features/desktopApp/desktopAppSlice";


class SocketService {
  callSocketOptions: SocketIOClient.ConnectOpts;
  messageObservable: BehaviorSubject<string>;
  socket: SocketIOClient.Socket | undefined;
  intervalData: any | undefined;
  ifReconnect: boolean = false;
  disconnectSocketId: string = "";
  socketTypeName: string;
  attemptNo = 0;
  isReconnectionOnce: boolean = false;
  disconnectByOwn:boolean = false;
  allConnectData = {
    hostName: "",
    serverIpHash: "",
    payload: "",
    iv: "",
    digest: "",
    viewMode: "",
    path: "",
    isWhiteboard: false,
    screenShare: false,
  };
  constructor(socketType: string) {
    this.callSocketOptions = {
      reconnection: false,
      // reconnectionDelay: 5000,
      // reconnectionAttempts: 20,
      // reconnectionDelayMax: 3000,
      rememberUpgrade: true,
      transports: ["websocket"],
    };
    this.messageObservable = new BehaviorSubject(
      JSON.stringify({ type: "DEFAULT" })
    );

    this.socketTypeName = socketType;
  }

  getRandomString() {
    return Math.random().toString(36).substring(2, 15);
  }

  async isOnline() {
    if (!window.navigator.onLine) return false;

    // avoid CORS errors with a request to your own origin
    const url = new URL(window.location.origin);

    // random value to prevent cached responses
    url.searchParams.set("rand", this.getRandomString());

    try {
      const response = await fetch(url.toString(), { method: "HEAD" });

      return response.ok;
    } catch {
      return false;
    }
  }

  reconnectFlushState = () => {
    this.isReconnectionOnce = false;

    this.stopIntervalData();

    this.ifReconnect = false;
    this.disconnectSocketId = "";
    this.attemptNo = 0;
  };

  connectAfterOffline = async () => {
    this.attemptNo++;

    if (this.attemptNo >= 10) {
      this.stopIntervalData();
      this.attemptNo = 0;
      toast.error("You lost connection, please re-enter the room");
      if(this.socketTypeName === socketConnectiontype.media ||
      this.socketTypeName === socketConnectiontype.whiteBoard ){
      window.location.href = "/dashboard";
      }
    }
    const checkOnline = await this.isOnline();
    console.log("dicsoccheck",this.attemptNo,this.socketTypeName)
    if (checkOnline && !this.isReconnectionOnce) {

      this.isReconnectionOnce = true;
      this.socketReconnect();
      this.stopIntervalData();
      this.ifReconnect = true;
      this.attemptNo = 0;
    }
  };

  socketReconnect = () => {
    this.connectToSocket(
      this.allConnectData.hostName,
      this.allConnectData.serverIpHash,
      this.allConnectData.payload,
      this.allConnectData.iv,
      this.allConnectData.digest,
      this.allConnectData.viewMode,
      this.allConnectData.path,
      this.allConnectData.isWhiteboard,
      this.allConnectData.screenShare,
      this.disconnectSocketId,
      true
    );
  }

  stopIntervalData = () => {
    console.log('99## OUT: stopIntervalData', this.intervalData)
    if (this.intervalData) {
      console.log('99## IN: stopIntervalData', this.intervalData)
      clearInterval(this.intervalData);
      this.intervalData = undefined
      console.log('99## END IN: stopIntervalData', this.intervalData)
    }
  }

  disconnectEventCheck = () => {
    if (
      this.socketTypeName === socketConnectiontype.media ||
      this.socketTypeName === socketConnectiontype.whiteBoard ||
      this.socketTypeName === socketConnectiontype.desktopApp ||
      this.socketTypeName === socketConnectiontype.chat ||
      this.socketTypeName === socketConnectiontype.activityChannel
    ) {
      if (!this.intervalData) {
        console.log('TTTT disconnectEventCheck',this.intervalData )
        // this.stopIntervalData();

      // this.closeSocketConnection();
        this.intervalData = setInterval(() => {
          this.connectAfterOffline();
        }, 5000);
      }
    }
  };

  connectToSocket = (
    hostName: string,
    serverIpHash: string,
    payload: string,
    iv: string,
    digest: string,
    viewMode: string = "",
    path: string,
    isWhiteboard: boolean = false,
    screenShare: boolean = false,
    reconnectSocketId: string = "",
    isReconnected: boolean = false
  ) => {
    this.callSocketOptions.query = {
      payload,
      iv,
      digest,
      viewMode,
      isWhiteboard,
      screenShare,
      reconnectSocketId,
      isReconnected,
    };
    this.allConnectData = {
      hostName,
      serverIpHash,
      payload,
      iv,
      digest,
      viewMode,
      path,
      isWhiteboard,
      screenShare,
    };
    this.callSocketOptions.path = path;
    this.socket = io(hostName, this.callSocketOptions);

    this.socket.on("connect", () => {
      console.log("SOCKET connection is established", { path: path });
    });
    this.socket.on("disconnect", (reason: string, details: any) => {
      console.log(
        "####",
        reason,
        this.socketTypeName,
        details,
        this.socket?.connected,
        this.socket,
        "socketcheck"
      );

      if(this.socketTypeName === socketConnectiontype.desktopApp) return;
      this.isReconnectionOnce = false;
      if(this.socketTypeName === socketConnectiontype.media && !this.disconnectByOwn){
        store.dispatch(disconnnectSocketDesktop());
      }
      if (reason === "transport close") {
        // toast("Your internet is disconnected, reconnecting.....");
        // notifyMe(`Your internet is disconnected, reconnecting.....`);
        this.disconnectEventCheck();
      } else if (reason === "io server disconnect") {
        this.messageObservable.next(
          JSON.stringify({
            type: "error",
            message: "socket disconnect from server",
          })
        );
        console.log("disconnected from server");
      }
      if (reason === "io client disconnect") {
        // this.messageObservable.next(
        //   JSON.stringify({
        //     type: "error",
        //     message: "socket disconnect from client",
        //   })
        // );
        console.log("disconnected from client",this.socketTypeName,this.disconnectByOwn);
        if(!this.disconnectByOwn){
          console.log("enter into io client inside",this.disconnectByOwn)
          this.disconnectEventCheck();
        }else{
          this.disconnectByOwn = false;
        }
      }
      if (reason === "ping timeout") {
        this.disconnectEventCheck();
        // this.disconnectEventCheck();
      }

      // if (reason === "transport close") {
      //   // toast("Your internet is disconnected, reconnecting.....");
      //   // notifyMe(`Your internet is disconnected, reconnecting.....`);

      //   this.disconnectEventCheck();
      // } else if (reason === "ping timeout") {
      //   // toast("lost connection,reconnect.....");
      //   // notifyMe(`lost connection, reconnecting.....`);
      //   console.log("ping timeout");
      //   this.disconnectEventCheck();
      // } else if (reason === "io server disconnect") {
      //   this.messageObservable.next(
      //     JSON.stringify({
      //       type: "error",
      //       message: "socket disconnect from server",
      //     })
      //   );
      //   console.log("disconnected from server");
      // } else if (reason === "io client disconnect") {
      //   this.messageObservable.next(
      //     JSON.stringify({
      //       type: "error",
      //       message: "socket disconnect from client",
      //     })
      //   );
      //   console.log("disconnected from client");
      // } else {
      //   // toast("Your connection is lost from room please exit and re-enter in room again");
      //   // notifyMe(`Your connection is lost from room please exit and re-enter in room again`);
      //   console.log(
      //     "Your connection is lost from room please exit and re-enter in room again"
      //   );
      // }
    });

    this.socket.on("onMessage", (messageRcvd: string) => {
      console.log(`Message in onMessage Hook ${messageRcvd}`);
      // const message = JSON.parse(messageRcvd);
      this.messageObservable.next(messageRcvd);
      this.messageObservable.next(JSON.stringify({ type: "DEFAULT" }));
    });

    this.socket.on("connect_error", (error: Error) => {
      console.log(error);
      this.messageObservable.next(
        JSON.stringify({
          type: "error",
          message: "socket connect error",
        })
      );
    });
    this.socket.on("connect_timeout", (timeout: string) => {
      console.log(timeout);
    });
  };

  timeoutCallback = (callback: Function) => {
    let called = false;

    const interval = setTimeout(() => {
      if (called) return;
      called = true;
      callback("", new Error("Request timed out"));
    }, 15000);

    return (...args: any) => {
      if (called) return;
      called = true;
      clearTimeout(interval);

      callback(...args);
    };
  };

  _sendMessage = (eventType: string, dataObject: Record<string, unknown>) => {
    return new Promise((resolve, reject) => {
      this.socket?.emit(
        eventType,
        JSON.stringify(dataObject),
        this.timeoutCallback((response: string, err: Error) => {
          if (err) reject(err);
          else {
            console.log(`data recvd for ${eventType} is: ${response}`);
            resolve(JSON.parse(response));
          }
        })
      );
    });
  };

  sendMessage = async (
    eventType: string,
    dataObject: Record<string, unknown>
  ) => {
    console.log(
      `data sent for event type: ${eventType}, dataObject: ${JSON.stringify(
        dataObject
      )}`
    );
    const requestRetries = 3;
    if (
      true ||
      eventType === "FACE_DETECTED" ||
      eventType === "WB_WHITEBOARD_ACTION" ||
      eventType === "REMOVE_PEER"
    ) {
      return new Promise((resolve) => {
        this.socket?.emit(
          eventType,
          JSON.stringify(dataObject),
          (dataRcvd: string) => {
            if (dataRcvd) {
              console.log(`data recvd is: ${dataRcvd}`);
              resolve(JSON.parse(dataRcvd));
            }
          }
        );
      });
    }
    for (let tries = 0; tries < requestRetries; tries++) {
      try {
        return await this._sendMessage(eventType, dataObject);
      } catch (error) {
        console.log(
          'sendRequest() | timeout, retrying [attempt:"%s"] [eventType: "%s"]',
          tries,
          eventType
        );
      }
    }
  };

  getMessages = () => this.messageObservable.asObservable();

  closeSocketConnection = (disconnectByOwn:boolean=false) => {
    if (this.socket && this.socket.connected) {
      console.log("call closeSocketConnection",this.socketTypeName);
      this.disconnectByOwn = disconnectByOwn
      this.socket.close();
    }
  };
}

export default class SocketServiceSingleton {
  static instance: SocketService;
  static chatInstance: SocketService;
  static whiteBoardInstance: SocketService;
  static desktopApp: SocketService;
  static activityChannelInstance: SocketService;

  constructor(socketType: string) {
    if (
      socketType === socketConnectiontype.chat &&
      !SocketServiceSingleton.chatInstance
    ) {
      console.log("chat constructror");
      SocketServiceSingleton.chatInstance = new SocketService(socketType);
    }
    if (
      socketType === socketConnectiontype.whiteBoard &&
      !SocketServiceSingleton.whiteBoardInstance
    ) {
      console.log("whiteboard constructror");
      SocketServiceSingleton.whiteBoardInstance = new SocketService(socketType);
    }
    if (
      socketType === socketConnectiontype.desktopApp &&
      !SocketServiceSingleton.desktopApp
    ) {
      console.log("desktopApp constructror");
      SocketServiceSingleton.desktopApp = new SocketService(socketType);
    }
    if (
      socketType === socketConnectiontype.media &&
      !SocketServiceSingleton.instance
    ) {
      console.log("media constructror");
      SocketServiceSingleton.instance = new SocketService(socketType);
    }
    if (
      socketType === socketConnectiontype.activityChannel &&
      !SocketServiceSingleton.activityChannelInstance
    ) {
      console.log("activitychannel constructror");
      SocketServiceSingleton.activityChannelInstance = new SocketService(
        socketType
      );
    }
  }
  getInstance(socketType: string) {
    if (socketType === socketConnectiontype.chat) {
      return SocketServiceSingleton.chatInstance;
    } else if (socketType === socketConnectiontype.whiteBoard) {
      console.log("whiteBoard instance");
      return SocketServiceSingleton.whiteBoardInstance;
    } else if (socketType === socketConnectiontype.desktopApp) {
      console.log("desktopApp instance");
      return SocketServiceSingleton.desktopApp;
    } else if (socketType === socketConnectiontype.activityChannel) {
      console.log("activityChannel instance");
      return SocketServiceSingleton.activityChannelInstance;
    } else {
      console.log("media instance");
      return SocketServiceSingleton.instance;
    }
  }
}
